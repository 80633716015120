import React from "react"
import Containers from "../components/container"
import Header from "../components/header"
import Headertop from "../components/headertop"
import Footer from "../components/footer"
import Formfooter from "../components/form-footer"
import { Container, Col, Row } from "react-bootstrap"
import * as garmentStyles from "../css/garment.module.css"
import * as homeStyles from "../css/index.module.css"
import { Icon } from '@iconify/react';
import chevronDoubleRight from '@iconify/icons-mdi-light/chevron-double-right';
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Bgsew from "../components/bgsew"
import Seo from "../components/seo"

const SeoTitle = "ด้ายเย็บกระสอบ"
const SeoDecription = "ด้ายเย็บกระสอบ เราจำหน่ายโพลีเอสเตอร์ 100% แข็งแรงทดทาน ใช้งานได้ดีคุณภาพสูง ด้วยประสบการณ์ในวงการกว่า 60 ปี"
const url = "/sew-a-sack/"

export default function sewasack({data}) {
    return (
    <Containers>
      <Seo 
        title={SeoTitle}
        description={SeoDecription}
        pathname={url}
       />
      <Headertop />
      <Header />
      <Bgsew />
      <div className={garmentStyles.herob}>
      <Container fluid="xl">
              <Row>
                  <Col><p><Link to="/">Home</Link>  <Icon icon={chevronDoubleRight} />  Product</p></Col>
              </Row>
      </Container>
      </div>
      <div className={garmentStyles.herosection}>
          <Container fluid="xl">
              <Row>
                <Col md={3}>
                <div className={garmentStyles.categories}>
                  <h3>หมวดหมู่สินค้า</h3>
                  <Link to="/categories/"><p className={garmentStyles.pmenu}>สินค้าทั้งหมด</p></Link>
                  <Link to="/garment/"><p className={garmentStyles.pmenu}>งานการ์เม้นท์</p></Link>
                  <Link to="/sew-a-sack/"><p className={garmentStyles.pmenuactive}>ด้ายเย็บกระสอบ</p></Link>
                  <Link to="/industrial-thread/"><p className={garmentStyles.pmenu}>ด้ายอุตสหกรรม</p></Link>
                  <Link to="/product-import/"><p className={garmentStyles.pmenu}>สินค้านำเข้า</p></Link>
                  <Link to="/other-products/"><p className={garmentStyles.pmenu}>สินค้าอื่นๆ</p></Link>
                </div>
                </Col>
                <Col md={9}>
                  <Containers>
                    <Row>
                      {data.allMarkdownRemark.edges.map(({ node }) => (
                        <Col xs={6} md={4} key={node.id} className={homeStyles.colproduct}>
                          <Link to={node.fields.slug}>
                            <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} className={homeStyles.imgproduct} />
                            <h3>{node.frontmatter.title}{" "}</h3>
                          </Link>
                        </Col>))}
                    </Row>
                  </Containers>
                </Col>
              </Row>
          </Container>
      </div>
        <Container className="pb-5">
          <Row>
            <Col md={12}>
              <p>
                ด้ายที่ใช้ในการเย็บกระสอบ แบ่งได้ตามประเภทตามวัสดุและลักษณะที่ใช้ในกระสอบต่างๆ
              </p>
              <h2>ตัวอย่างของที่ใช้ ด้ายเย็บกระสอบ ได้แก่:</h2>
              <ul>
                <li><b>ด้ายพลาสติก (Polypropylene, PP):</b> เป็นด้ายที่ใช้งานอย่างแพร่หลายในการเย็บกระสอบ มีความแข็งแรงและทนทานต่อสภาพอากาศและสารเคมี นอกจากนี้ยังมีความยืดหยุ่นและความต้านทานต่อแรงเสียดทาน</li>
                <li><b>ด้ายเทียม (Polyester, PET):</b> เป็นด้ายที่มีความแข็งแรงและต้านทานต่อแรงเสียดทานมาก สามารถทนต่อความร้อนและแสงแดดได้ดี มีความยืดหยุ่นในการใช้งานและมีความต้านทานต่อสารเคมี</li>
                <li><b>ด้ายไนลอน (Nylon):</b> เป็นด้ายที่มีความแข็งแรงสูง และมีความยืดหยุ่นในการใช้งาน ทนต่อการเกิดเส้นต่างๆ และมีความต้านทานต่อสารเคมี</li>
                <li><b>ด้ายไฟเบอร์กลาส (Fiber glass, FG):</b> เป็นด้ายที่ทำจากใยแก้ว มีความแข็งแรงสูงและทนทานต่อสภาพอากาศ มีความต้านทานต่อสารเคมีและความร้อน นิยมใช้ในกระสอบที่ต้องการความแข็งแรงพิเศษ</li>
                <li><b>ด้ายไฟเบอร์ (Polypropylene Multifilament, PP MF):</b> เป็นด้ายที่ผลิตจากพลาสติกพรอพิลีน มีความแข็งแรงและทนทานต่อสภาพอากาศ แต่มีความนุ่มนวลและยืดหยุ่น นิยมใช้ในกระสอบที่ต้องการความสวยงามและความยืดหยุ่น</li>
                <li><b>ด้ายโพลีเอสเตอร์ (Polyester Staple Fiber, PSF):</b> เป็นด้ายที่ทำจากพลาสติกโพลีเอสเตอร์ มีความแข็งแรงและทนทานต่อสภาพอากาศ และมีความต้านทานต่อสารเคมี นิยมใช้ในกระสอบที่ต้องการความแข็งแรงและความทนทาน</li>
              </ul>
              <p>
                โดยแต่ละประเภทของด้ายสามารถมีคุณสมบัติเสริมเพิ่มเติม เช่น การทนต่อแสงแดด การทนต่อสารเคมี เป็นต้น สามารถเลือกใช้ด้ายที่เหมาะสมกับความต้องการและเงื่อนไขการใช้งานของกระสอบได้
              </p>
              <h4>ด้ายเย็บกระสอบมีคุณสมบัติที่ดีและมีความสำคัญต่อความคุ้มค่าและคุณภาพของกระสอบ</h4>
              <p><b>คุณสมบัติที่ดีของด้ายเย็บกระสอบ ได้แก่</b></p>
              <ol>
                <li><b>ความแข็งแรง:</b> ด้ายเย็บกระสอบควรมีความแข็งแรงเพื่อให้กระสอบมีความทนทานต่อการใช้งานและการขนส่ง ด้ายที่แข็งแรงช่วยให้กระสอบสามารถรับน้ำหนักและแรงเสียดทานได้ดี</li>
                <li><b>ความยืดหยุ่น:</b> ด้ายเย็บกระสอบควรมีความยืดหยุ่นเพียงพอเพื่อให้สามารถรองรับการยืดหดของกระสอบได้โดยไม่เกิดการรั่วซึมหรือแตกหัก ความยืดหยุ่นช่วยให้กระสอบสามารถปรับตัวตามขนาดและรูปร่างของสินค้าที่อยู่ข้างในได้อย่างเหมาะสม</li>
                <li><b>ความทนทานต่อสภาพอากาศ:</b> ด้ายเย็บกระสอบควรมีความทนทานต่อสภาพอากาศ เช่น ความทนทานต่อแสงแดด ความต้านทานต่อความชื้น และความทนทานต่อสารเคมี เพื่อให้กระสอบสามารถใช้งานในสภาวะแวดล้อมต่างๆ ได้</li>
                <li><b>ความต้านทานต่อแรงเสียดทาน:</b> ด้ายเย็บกระสอบควรมีความต้านทานต่อแรงเสียดทาน เพื่อให้กระสอบสามารถรับแรงกดหรือแรงเสียดทานจากการโหลดหรือการขนส่งได้โดยไม่เกิดการรั่วซึมหรือแตกหัก</li>
                <li><b>ความสวยงาม:</b> ด้ายเย็บกระสอบควรมีความสวยงามและเป็นระเบียบเรียบร้อย เพื่อให้กระสอบมีความดูดีและมีมาตรฐานที่สูง</li>
              </ol>
              <p>
              อย่างไรก็ตาม คุณสมบัติที่ดีของด้ายเย็บกระสอบอาจแตกต่างกันไปตามประเภทของด้ายและการใช้งานที่ต้องการ ในการเลือกใช้ด้ายเย็บกระสอบควรพิจารณาตามความต้องการและเงื่อนไขการใช้งานของกระสอบเพื่อให้ได้ผลิตภัณฑ์ที่มีคุณภาพสูง
              </p>
            </Col>
          </Row>
        </Container>
      <Formfooter />
      <Footer />
    </Containers>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: "sew-a-sack" }}}
      sort: { fields: [frontmatter___date], order: DESC }
      ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`